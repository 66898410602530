import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-family: 'Fira Mono', monospace;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`

  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;   
`;

export const mintboxContainer = styled.div
`
@media only screen and (min-width:700px) {
  margin: 0 auto;
  height: 31%;
  width:  70%;
}
@media only screen and (max-width:700px) {
  width: 150%;
}
`

export const RoadMapBgContainer = styled.div`
position: relative; 
top: 0; 
left: 0; 
background-image: ${({ image }) => (image ? `url(${image})` : "none")};
background-repeat: no-repeat;

/* Preserve aspet ratio */
width: 100vw;
height: 100vh;
       

`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const countdownSpacing = styled.div
`
@media only screen and (min-width:700px) {
    display: inline-block;
    align-items: center;
    list-style-type: none;
    padding: 1%;
    margin-left: 20x;
}

@media only screen and (max-width:700px) {
  display: inline-block;
  align-items: center;
  list-style-type: none;
  margin-left: 20x;
}
  `;

  export const countdownList = styled.li
  `
  display: inline-block;
  list-style-type: none;
  color: var(--secondary);
  text-transform: uppercase;

  @media only screen and (min-width:700px) {
    font-size: calc(.7vw + .40rem);
    padding: 4%;
  }
  @media only screen and (max-width:700px) {
    padding: 1%;
    padding-bottom: 20%;
    font-size: .5rem;
  }
  `
  export const GIFList = styled.li
  `
    display: inline-block;
    float: left;
    list-style-type: none;
    padding: 5%;
    width: 50%;
  `

  export const TeamList = styled.li
  `
    display: inline-block;
    width: 20%;
    padding: 1%;
    font-size: calc(.8rem + .8vw);
    text-align: center;
    @media only screen and (max-width:700px) {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      padding: 5%;
      font-size: calc(.8rem + .8vw);
      text-align: center;
    }
  `

  export const countdownBody = styled.div
  `
  `
  export const CountdownNumbers = styled.div
  `
  color: var(--countdown);
  line-height: 0;
  @media only screen and (min-width:700px) {
    font-size: calc(2vw + 1rem);
    width: 100%;
    padding-top 10%;
    padding-left: 8%;
  }
  @media only screen and (max-width:700px) {
    padding-top: 20%;
    font-size: 1.1rem;
    padding-left: 5%;
  }
  `
  export const MintTitle = styled.div
  `
    padding-top 10%;
    padding-bottom 3%;
    font-size calc(1rem + 1vw);
    text-align: center;
    color: var(--top-text);
    font-weight: 900;
    text-transform: uppercase;

  `
  export const SocialDescription = styled.div
  `
    padding-top 1%;
    font-size calc(1rem + 1vw);
    text-align: center;
    color: var(--top-text); 
  `
  export const CarouselDescription = styled.div
  `
    paddings 10%;
    font-size calc(.5rem + .5vw);
    text-align: center;
    color: var(--top-text); 
  `
  export const social = styled.li
  `
  @media only screen and (min-width:800px) {
    display: inline-block;
    font-size: 1rem;
    list-style-type: none;
    padding: 1.5%;
    text-transform: uppercase;
  }
  @media only screen and (max-width:800px) {
    display: inline-block;
    font-size: .5rem;
    list-style-type: none;
    text-transform: uppercase;
  }
  `
  export const osLi = styled.li
  `
    display: inline-block;
    font-size: 1rem;
    list-style-type: none;
    position: abosolute;
    text-transform: uppercase;
    margin-bottom: 10%;
  `
  //controls size of dicord/twitter icons -> need to rename 
  export const twitter = styled.img
  `  
  @media only screen and (max-width:800px) {
    width: 20vw;
    height: 20vw;
  }
  @media only screen and (min-width:800px) {
    width: 5vw;
    height: 5vw;
  }
  `

  export const OpenSea = styled.img
  `  
  @media only screen and (max-width:800px) {
    width: 10vw;
    height: 10w;
  }
  @media only screen and (min-width:800px) {
    width: 3vw;
    height: 3vw;
  }
  `

  export const mintBox = styled.div
  `
  @media only screen and (min-width:700px) {
    width: 65%;
    height: 225vh;
    margin: 0 auto;
  }
  @media only screen and (max-width:700px) {
    width: 50%;
    margin: 0 auto;
    margin-left: 45px;
  }
  `
  export const countdownBox = styled.div
  `
  width: 60%;
  margin: 0 auto;
  `

  export const FirstPage = styled.div
  `
  width: 100%;
  height: min(105vh);
  
  `

  export const SocialWrapper = styled.div
  `
  display: inline-block;
  align-items: center;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: center;
  text-align: center;
  padding-top: 3%;
   
  
  @media only screen and (min-width:700px) {
    width: 100%;
  }

  @media only screen and (max-width:700px) {
    width: 100%;
  }
  `
  export const SplitterText = styled.div
  `
  font-size: calc(3vw + 1rem);
  padding-left: 1px;
  display: block;
  color: var(--countdown);
  text-align: center;
  margin: auto;
  padding 3%;
  `
  export const RoadmapText = styled.div
  `
  font-size: calc(1vw + 1rem);
  padding-left: 1px;
  display: block;
  color: var(--countdown);
  text-align: left;
  margin: auto;
  padding 3%;
  `
  export const LogoBox = styled.div
  `
  text-align: center;
  `
  export const ShowcaseBg = styled.div
  `
    background-color: 
    var(--primary);
    --top-text: #7D00F9;
  `
  export const RoadmapBg = styled.div
  `
    background-color: var(--primary);
  `

  export const gifFit = styled.div
  `
    width: 50%;
    height: 100%
    display: block;
  `

  export const CtUL = styled.ul
  `
    width: 50%px;
  ` 

  export const roadmapimg = styled.img 
  `
    width: 100%;
    height: 25%;
  `
  export const calendarImg = styled.img 
  `
    width: 100%;
    height: 80%;
    @media only screen and (min-width:700px) {
      height: 80%;
    }
  `

  export const slideshowImg = styled.img 
  `
  width:100vw;
  height:80vh;
  margin: auto;
  `

  export const datelist = styled.ul
  `
  @media only screen and (min-width:700px) {
    padding-left: 6%;
  }
  @media only screen and (max-width:700px) {
    padding-left: 5%;
  }
  `
  export const taterImgStyle = styled.h4
  `
  background-color: var(--potatoimg);
  `

  export const TeamImages = styled.ul
  `
    text-align: center;
  `

  export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size calc(.75rem + .3vw);
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
padding-top 1%;
font-size calc(1rem + 1vw);
text-align: center;
color: var(--top-text); 
`;

export const TextTitle = styled.p`
padding-top 10%;
font-size calc(2rem + .1vw);
text-align: center;
color: var(--top-text);
font-weight: 900;
text-transform: uppercase;
`;

export const SubTextTitle = styled.p`
padding-top 3%;
padding-left: 5%;
padding-right: 5%;
font-size calc(.75rem + .75vw);
text-align: center;
color: var(--top-text);
font-weight: 900;
text-transform: uppercase;
`;



export const IncrementLI = styled.li
`
display: inline-block;
list-style-type: none;
color: var(--secondary);
text-transform: uppercase;

@media only screen and (min-width:700px) {
  font-size: calc(.7vw + .40rem);
  padding: 4%;
}
@media only screen and (max-width:700px) {
  padding: 1%;
  padding-bottom: 10%;
  font-size: .5rem;
}
`


export const IncrementUL = styled.ul
  `
  text-align: center;
  @media only screen and (min-width:700px) {
    margin: auto;
    width:  80%;
  }
  @media only screen and (max-width:700px) {
    width: 100%;
  }

`