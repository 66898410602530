import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import  Carousel  from "react-bootstrap/Carousel";



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 15px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
  background-position: center;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  :active {
    color: var(--secondary-text)
  };
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: inline-block;
`;

export const StyledLogo = styled.img`
width: 35vw;

  @media only screen and (max-width:800px) {
        width:250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`

export const StyledGIF = styled.img
`
width: 50%;
height: 100%;

`

export const StyledImg = styled.img`
  border: 4px solid var(--primary);
  
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [connecting, setConnecting] = useState(false);
//  const [feedback, setFeedback] = useState(`Click mint to grab your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    console.log("Cost: ", totalCostWei);
//    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const connectWallet = () => {
    setConnecting(true);
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const calculateTimeLeft = () => {
    let year = 2022;
    var difference = new Date(`${year}-01-09`) - +new Date();
    console.log(difference);
    // add 9 hours in miliseconds
    difference += 32400000;
    let timeLeft = {};
  
    if (difference > -1) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
  
    return timeLeft;
  };



  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  
  const timerComponents = [];
  
  Object.keys(timeLeft).forEach((interval) => {
    if (timeLeft[interval] < 10) {
      timeLeft[interval] = '0' + timeLeft[interval];
    }
    if (timeLeft[interval] < 0) {
      return;
    }
  
    timerComponents.push(
      <span>
        {timeLeft[interval]} {<s.countdownSpacing> </s.countdownSpacing>}
      </span>
    );
  });
  

  return (
    <s.Screen>
      <s.FirstPage>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)", height: "100vh"}}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/topbg.jpg" : null}
      >
        <s.LogoBox>
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        </s.LogoBox>
        <s.SpacerSmall />

          <s.Container flex={1} jc={"center"} ai={"center"}>
            
          </s.Container>
          <s.SpacerSmall />
          
          <s.mintBox>
          <s.mintboxContainer
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--primarybox)",
              borderRadius: 0,
              border: "4px solid var(--primarybox)",
              boxShadow: '0px 5px 55px #ffffff',
              overflow: 'hidden',
              opacity: "95%"
            }}
          >
            <s.TextSubTitle
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
            </s.TextSubTitle>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextSubTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextSubTitle>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                 {/*  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}. */} Free PPH NFTS!
                </s.TextTitle>
                <s.SubTextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                 {/*  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}. */} Mint more and save on gas fees! <br></br> (10 mint max)
                </s.SubTextTitle>
                <s.SpacerXSmall />
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}
                  style={{
                    margin: "auto",
                    textAlign: "center"
                  }}
                  >
                    
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>

              <s.SpacerSmall />
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
   {       /*          <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                      >
                      {feedback}
                    </s.TextDescription>
                    */}

                  <s.IncrementUL>
                    <s.IncrementLI>
                   
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      </s.IncrementLI>
                        
                        <s.IncrementLI>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      </s.IncrementLI>

                      <s.IncrementLI>
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
              
                      </s.IncrementLI>
                      </s.IncrementUL>
         
                    
                    <s.Container ai={"center"} jc={"center"} fd={"row"}
                      style={{height: "1%", textAlign: "center"}}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                        style={{fontSize: "font-size calc(1.5rem + 1.5vw);"}}
                      >
                        {claimingNft ? "BUSY" : "MINT"}
                      </StyledButton>
                      <br />
                    </s.Container>
                    <s.SpacerSmall />
                  </>
                )}
              </>
            )}
            <s.SpacerSmall />

            <s.SocialWrapper>
              <ul>
              <s.social>
              <a href="https://discord.com/invite/BthSr8My" target="_blank" rel="noopener noreferrer"><s.twitter img src="/config/images/discord.svg" /></a>
                </s.social>
                <s.social>
                  <a href="https://opensea.io/collection/pronounpotatoheads" target="_blank" rel="noopener noreferrer"><s.twitter img src="/config/images/opensea.svg"/></a>
                  </s.social>
                <s.social>
              <a href="https://www.instagram.com/pronounpotatoes/" target="_blank" rel="noopener noreferrer"><s.twitter img src="/config/images/instagram11.svg"/></a>
                </s.social>
              <s.social>
              <a href="https://twitter.com/PronounPotatoes" target="_blank" rel="noopener noreferrer"><s.twitter img src="/config/images/twitter.svg"/></a>
                </s.social>

                
 
              </ul>
            </s.SocialWrapper>
          </s.mintboxContainer>
          </s.mintBox>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>

          </s.Container>

        <s.SpacerMedium />

      </s.Container>
      </s.FirstPage>
{
     <s.Container>
          <s.SplitterText
           style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>


         <h1>OUR STORY</h1>
          </s.SplitterText>
        </s.Container>
          }
          {
        <s.calendarImg img src="/config/images/Story.jpg" /> 
          }
       


  {/*
      <s.Container>
          <s.SplitterText
           style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>


         <h1>CALENDAR</h1>
          </s.SplitterText>
        </s.Container>
          */}
{/*
        <s.calendarImg img src="/config/images/Calendar.png" />
*/}

        {/* <s.Container>
          <s.SplitterText
           style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>

         <h1>THE TATER MODEL</h1>
          </s.SplitterText>
        </s.Container> */}
       
     {/* <s.ShowcaseBg>
            
              
            <Carousel>
          <Carousel.Item>
            <s.slideshowImg
              src="/config/images/Mission_Statement.png"
            ></s.slideshowImg>
          </Carousel.Item>
          <Carousel.Item>
          <s.slideshowImg
              src="/config/images/Vision_Statement.png"
            ></s.slideshowImg>
          </Carousel.Item>
          <Carousel.Item>
            <s.slideshowImg
              src="/config/images/Transparency.png"
            ></s.slideshowImg>
          </Carousel.Item>
          <Carousel.Item>
            <s.slideshowImg
              src="/config/images/Utility.png"
            ></s.slideshowImg>
          </Carousel.Item>
          <Carousel.Item>
          <s.slideshowImg
              src="/config/images/Collaboration.png"
            ></s.slideshowImg>
          </Carousel.Item>
        </Carousel>

               <ul>
              <s.GIFList>
                  <img src="/config/images/gif1.gif" alt="Discord" style={{width:"100%"}}/>
                </s.GIFList>
                <s.GIFList>
                  <img src="/config/images/gif2.gif" alt="Discord" style={{width:"100%"}}/>
                </s.GIFList> 
              </ul>
             
             <ul>
                <s.GIFList>
                  <img src="/config/images/gif3.gif" alt="Discord" style={{width:"100%"}}/>
                </s.GIFList>
                <s.GIFList>
                  <img src="/config/images/gif4.gif" alt="Discord" style={{width:"100%"}}/>
                </s.GIFList>  
             </ul>
                
           
     </s.ShowcaseBg> */}


{/*}
     <s.RoadmapBg>
     <s.SplitterText
           style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>
        
       <h1>ROADMAP</h1> 
          </s.SplitterText>

        <s.roadmapimg img src="/config/images/roadmap.jpg" />

        <s.RoadmapText>
        
<Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>25% - OGs and Giveaways</Accordion.Header>
        <Accordion.Body
            style={{
          color: "var(--primarytext)",
          backgroundColor: "var(--primarybox)",
          fontSize: "15px"
        }}>
        Five Moderators selected (each receiving their own 1:1 with features they choose), as well as five 1:1 mega rare Pronoun Potatoes that will be given away at random based on a list of wallets of current holders. (Snapshot of wallets will be taken on Etherscan from the holders’ section by 5PM EST on the day we meet 25%)
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>50% - Sweep the Floor and Give Back to the Community</Accordion.Header>
        <Accordion.Body
            style={{
              color: "var(--primarytext)",
              backgroundColor: "var(--primarybox)",
              fontSize: "15px"
            }}>
        Buy the bottom seven Potatoes and give them away to the top seven community members that have invited the most people to the Discord server. (Tracked by InviteTracker; moderators and admins may not participate)
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" transition="none">
        <Accordion.Header>75% - Merchandise Store Opens and We Donate</Accordion.Header>
        <Accordion.Body
            style={{
              color: "var(--primarytext)",
              backgroundColor: "var(--primarybox)",
              fontSize: "15px"
            }}>
        The Merchandise store opens with products hosting your favorite potato heads, as well as some other products! We will also make a large charitable contribution to a Foundation to be named later!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>100% - Vacation Time</Accordion.Header>
        <Accordion.Body
            style={{
              color: "var(--primarytext)",
              backgroundColor: "var(--primarybox)",
              fontSize: "15px"
            }}>
        One lucky wallet holder gets an all-expenses paid 5-Day vacation for two to the Caribbean and a $500.00 VISA Gift Card for additional expenses while they are there.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>



          

        </s.RoadmapText>
        <s.SplitterText
            style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>
             */}

<s.RoadmapBg>
<s.SplitterText
            style={{
            backgroundColor: "var(--showcase)",
            width: "100%"
           }}>
            <h1>OUR TEAM</h1>
            
    </s.SplitterText>
           <s.TeamImages>
              <s.TeamList>
                <div>
                  <img src="/config/images/Luke.jpg" alt="Discord" style={{width:"100%"}}/>

                  <h4>Tater Flow - <br/>CFO Daddy</h4>
                </div>
                </s.TeamList>
                <s.TeamList>
                <img src="/config/images/tatersalad.jpg" alt="Discord" style={{width:"100%"}}/>
                  <h4>Tater Salad - <br/>COO</h4>
                </s.TeamList>
                <s.TeamList>
                <img src="/config/images/Jon.jpg" alt="Discord" style={{width:"100%"}}/>
                  <h4>Master Tater - <br/>CMO</h4>
                </s.TeamList>
                <s.TeamList>
                <img src="/config/images/Sean.png" alt="Discord" style={{width:"100%"}}/>
                  <h4>Worm A Toe  - <br/> Artist</h4>
                </s.TeamList> 
                <s.TeamList>
                <img src="/config/images/josh.png" alt="Discord" style={{width:"100%"}}/>
                <p style={{padding: "2%"}}>
                  <h5>Tater Jmo -<br/> CTO</h5>
                </p>
                </s.TeamList> 
            </s.TeamImages>   

            </s.RoadmapBg>
          
            

    </s.Screen>
  );
}

export default App;
